import { useState, useEffect, Fragment, useRef, useLayoutEffect } from "react";

import { Button, Typography } from "@material-ui/core";
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import "./ModuleBlocker.scss";
import { useHistory } from "react-router-dom";

interface ModuleBlockerProps {

}

export const ModuleBlocker = (props: ModuleBlockerProps) => {
  const history = useHistory();
  
  const handleGoToFinance = () => {
    history.push('/financeiro');
  };
  
  return (
    <Fragment>
      <div className="blocker">
        <div className="blocker__container">
          <div className="blocker__container__note">
            <div className="blocker__container__note__icon">
              <LockRoundedIcon />
            </div>
            <Typography className="blocker__container__note__title" variant="h6">
              Essa funcionalidade está bloqueada
            </Typography>
            <Typography className="blocker__container__note__subtitle" variant="subtitle1">
              Para desbloquea-la, acesse o menu financeiro e adquira já.
            </Typography>
            <div className="blocker__container__note__actions">
              <Button variant="contained" color="primary" onClick={handleGoToFinance}>Acessar Financeiro</Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
