import Typography from "@material-ui/core/Typography";

import { WarningBarStyles } from "./WarningBarStyles";

interface WarningBarProps {
  text: string | JSX.Element;
  clickableText: string;
  clickAction?: () => void;
  altColor?: boolean;
  blueColor?: boolean;
  customColor?: string;
  notClickable?: boolean;
}

export const WarningBar = (props: WarningBarProps) => {
  const {
    text,
    clickableText,
    clickAction,
    altColor,
    blueColor,
    customColor,
    notClickable,
  } = props;
  const classes = WarningBarStyles();

  const getBackgroundColor = () => {
    if (altColor) return classes.orangeBackground;
    if (blueColor) return classes.blueBackground;

    return classes.redBackground;
  };

  const getTextColor = () => {
    if (altColor) return classes.orangeText;
    if (blueColor) return classes.blueText;

    return classes.redText;
  };

  return (
    <div className={`${classes.warningSection} ${getBackgroundColor()}`} style={customColor && {backgroundColor: customColor}}>
      <Typography variant="subtitle1" className={classes.warning}>
        {text}
        <p
          className={`${notClickable ? classes.warningLinkAlt : classes.warningLink}  ${notClickable ? "" : getTextColor()}`}
          style={customColor && {color: customColor}}
          onClick={clickAction}
        >
          {clickableText}
        </p>
      </Typography>
    </div>
  );
};
