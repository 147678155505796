

import { IconButton, Slider, Typography } from "@material-ui/core";
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import WaveSurfer from 'wavesurfer.js';

import "./ChatAudioPlayer.scss";
import { useEffect, useRef, useState } from "react";

interface ChatAudioPlayerProps {
  uuid: string;
  src: string;
  time: string;
  withWave?: boolean;
}

export const ChatAudioPlayer = (props: ChatAudioPlayerProps) => {
  const {
    uuid,
    src,
    time,
    withWave = false,
  } = props;
  const audio = useRef<HTMLAudioElement>();
  const wave = useRef<WaveSurfer>();
  // const seekTimeout = useRef<NodeJS.Timeout>();
  const [currentSeek, setCurrentSeek] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [durationInSeconds, setDurationInSeconds] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isPlayingBeforeChange, setIsPlayingBeforeChange] = useState<boolean>(false);

  useEffect(() => {
    if (withWave) {
      wave.current = WaveSurfer.create({
        barWidth: 3,
        container: `#waveform-${uuid}`,
        backend: 'WebAudio',
        height: 40,
        progressColor: '#2D5BFF',
        waveColor: '#636b70',
        cursorWidth: 0,
        cursorColor: 'transparent',
        // dragToSeek: true,
        url: src
      });

      wave.current.on("ready", handleOnAudioLoad);
      // wave.current.on("seeking", handleWaveSeeking);
      wave.current.on("finish", handleOnAudioEnded);
      wave.current.on("timeupdate", handleOnAudioTimeUpdate);
    } else {
      audio.current = new Audio(src);
    
      audio.current.addEventListener("loadeddata", handleOnAudioLoad);
      audio.current.addEventListener("ended", handleOnAudioEnded);
      audio.current.addEventListener("timeupdate", handleOnAudioTimeUpdate);
    }

    return () => {
      if (withWave) {
        wave.current.destroy();
      } else {
        audio.current.removeEventListener("loadeddata", handleOnAudioLoad);
        audio.current.removeEventListener("ended", handleOnAudioLoad);
        audio.current.removeEventListener("timeupdate", handleOnAudioLoad);
      }
    };
  }, [src]);

  const handleOnAudioLoad = () => {
    if (withWave) {
      wave.current.setVolume(.5);

      setDurationInSeconds(Math.floor(wave.current.getDuration()));
    } else {
      audio.current.volume = .5;

      setDurationInSeconds(Math.floor(audio.current.duration));
    }
  };

  const handleOnAudioEnded = () => {
    if (withWave) {
      wave.current.seekTo(0);
    }

    setIsPlaying(false);
    setCurrentSeek(0);
    setCurrentTime(0);
  };

  const handleOnAudioTimeUpdate = () => {
    let newCurrentTime: number = 0;

    if (withWave) {
      newCurrentTime = wave.current.getCurrentTime();
    } else {
      newCurrentTime = audio.current.currentTime;
    }

    setCurrentSeek(newCurrentTime);
    setCurrentTime(Math.floor(newCurrentTime));
  };

  const handleToggleState = () => {
    setIsPlaying(!isPlaying);

    if (withWave) {
      wave.current.playPause();
    } else {
      if (audio.current.paused) {
        audio.current.play();
      } else {
        audio.current.pause();
      }
    }
  };
  
  // const handleWaveSeeking = () => {
  //   if (wave.current.isPlaying()) {
  //     wave.current.pause();
  //     setIsPlaying(false);
  //     setIsPlayingBeforeChange(true);

  //     if (seekTimeout.current) clearTimeout(seekTimeout.current);

  //     setTimeout(handleSeekCommitted, 500);
  //   }
  // };

  const handleSliderChange = (event: any, value: number | number[]) => {
    const newValue = value as number;

    if (!audio.current.paused) {
      audio.current.pause();
      setIsPlaying(false);
      setIsPlayingBeforeChange(true);
    }

    audio.current.currentTime = newValue;

    setCurrentSeek(newValue);
    setCurrentTime(Math.floor(newValue));
  };

  const handleSeekCommitted = () => {
    if (isPlayingBeforeChange) {
      handleToggleState();
      setIsPlayingBeforeChange(false);
    }
  };

  const renderTimeFromSeconds = (seconds: number): string => {
    let minutes = seconds > 60 ? seconds / 60 : 0;
    seconds -= minutes * 60;
    const hours = minutes  > 60 ? minutes / 60 : 0;
    minutes -= hours * 60;

    const secondsFormatted = String(seconds % 60).padStart(2, '0');
  
    if (hours === 0) return `${minutes}:${secondsFormatted}`;

    const minutesFormatted = String(minutes % 60).padStart(2, '0');

    return `${String(hours).padStart(2, '0')}:${minutesFormatted}:${secondsFormatted}`;
  };

  return (
    <div className="chat-audio-player">
      <div className="chat-audio-player__content">
        <div className="chat-audio-player__content__left-container">
          <div className="chat-audio-player__content__left-container__action">
            <IconButton aria-label="toggle-audio-state" component="span" onClick={handleToggleState}>
              {!isPlaying ? <PlayArrowRoundedIcon /> : <PauseRoundedIcon />}
            </IconButton>
          </div>
        </div>
        <div className="chat-audio-player__content__right-container">
          {withWave && (
            <div className="chat-audio-player__content__right-container__wave" id={`waveform-${uuid}`}></div>
          )}
          {!withWave && (
            <div className="chat-audio-player__content__right-container__timeline">
              <Slider 
                value={currentSeek} 
                step={0.1}
                max={durationInSeconds}
                onChange={handleSliderChange} 
                onChangeCommitted={handleSeekCommitted}
                aria-labelledby="continuous-slider" />
            </div>
          )}
        </div>
      </div>

      <div className="chat-audio-player__footer">
        <Typography variant="caption">
          {renderTimeFromSeconds(isPlaying ? currentTime : durationInSeconds)}
        </Typography>
        <Typography variant="caption">
          {time}
        </Typography>
      </div>
    </div>
  );
};
