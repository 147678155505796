import { useState, useEffect, Fragment, useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  CustomerData,
  AuthContextProps,
  SettingsContextProps,
  OmnichannelDataChat,
  OmnichannelDataChatMessage,
  WppAccountMessageType,
  OmnichannelWppAccountMessagesResponse,
  AddonOmnichannelType,
  AddonOmnichannelStatus,
  AddonType,
  WppAccountMessageAck,
} from "../../data/types";

import "./ChatsOmnichannel.scss";
import { Button, CircularProgress, IconButton, InputBase, TextField, Typography, makeStyles } from "@material-ui/core";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import MicRoundedIcon from '@material-ui/icons/MicRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import SpeakerNotesOffRoundedIcon from '@material-ui/icons/SpeakerNotesOffRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { getUserOmnichannels } from "../../../general/api/homePageUserAPI";
import { useHttpClient } from "../../../shared/hooks/httpHook";
import { ChatAudioPlayer } from "./ChatAudioPlayer";
import { getWppAccountMessages } from "../../../user/api/settingsAPI";
import { ModuleBlocker } from "./ModuleBlocker";

const TREATED_MESSAGE_TYPES = [
  WppAccountMessageType.AUDIO,
  WppAccountMessageType.CHAT,
  WppAccountMessageType.IMAGE,
  WppAccountMessageType.PTT,
];

const PLACEHOLDER_CHATS: OmnichannelDataChat[] = [{
  id: 'placeholder-chat',
  name: 'Fulano',
  vendorName: 'Beltrano',
  picture: '',
  phones: ['5511987654321'],
  messages: [
    {
      id: 'placeholder-message-1',
      uuid: 'placeholder-message-1',
      type: WppAccountMessageType.CHAT,
      content: 'Lorem ipsum',
      timestamp: Date.now() / 1000,
      viewed: false,
      forwarded: false,
      ack: WppAccountMessageAck.SENT,
      fromMe: false,
    }, {
      id: 'placeholder-message-2',
      uuid: 'placeholder-message-2',
      type: WppAccountMessageType.CHAT,
      content: 'Lorem ipsum',
      timestamp: Date.now() / 1000,
      viewed: false,
      forwarded: false,
      ack: WppAccountMessageAck.READ,
      fromMe: true,
    },
  ]
}];

interface ChatsOmnichannelProps {
  auth: AuthContextProps;
  customer: CustomerData;
  settingsCtx: SettingsContextProps;
}

export const ChatsOmnichannel = (props: ChatsOmnichannelProps) => {
  const { auth, customer, settingsCtx } = props;
  const { sendRequest } = useHttpClient();
  const intersection = useRef(null);
  const msgbox = useRef(null);
  const [showSearchContainer, setShowSearchContainer] = useState<boolean>(false);
  const [isMobileMode, setIsMobileMode] = useState<boolean>(false);
  const [isVendorMode, setIsVendorMode] = useState<boolean>(false);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [activeChat, setActiveChat] = useState<OmnichannelDataChat>();
  const [messages, setMessages] = useState<OmnichannelDataChatMessage[]>([]);
  const [messagesCurrentPage, setMessagesCurrentPage] = useState<number>();
  const [messagesTotalPages, setMessagesTotalPages] = useState<number>();
  const [originalChats, setOriginalChats] = useState<OmnichannelDataChat[]>([]);
  const [filterChats, setFilterChats] = useState<OmnichannelDataChat[]>([]);
  const [searchMessages, setSearchMessages] = useState<OmnichannelDataChatMessage[]>([]);

  const individualPerm = settingsCtx.individualPermissions?.find((ip) => {
    return ip.user === auth.userId;
  });
  const hasPermissionToUse = settingsCtx.addons.some(addon => addon.type === AddonType.OMNICHANNEL && 
    addon.item.type === AddonOmnichannelType.WHATSAPP && 
    addon.item.status === AddonOmnichannelStatus.ACTIVE);

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobileMode(window.innerWidth < 1024);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    getUserOmnichannels({sendRequest, auth, settingsCtx});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);

        if (entry.isIntersecting) handleLoadMorePastMessages();
      },
      { rootMargin: "-100px" }
    );

    if (intersection.current) observer.observe(intersection.current);

    return () => observer.disconnect();
  }, [intersection, messagesTotalPages]);

  useEffect(() => {
    if (!hasPermissionToUse) {
      setOriginalChats(PLACEHOLDER_CHATS);
      setFilterChats(PLACEHOLDER_CHATS);
      setActiveChat(PLACEHOLDER_CHATS[0]);
      return;
    }

    if (settingsCtx.wppAccounts.length) {
      let chats: OmnichannelDataChat[] = [];

      settingsCtx.wppAccounts.forEach(account => {
        if (auth.type === 'VENDOR' && !individualPerm.viewAllChatsManager) {
          if ((!('vendor' in account) || (typeof account.vendor === 'object' && account?.vendor.id !== auth.userId))) return;
        }

        account.chats.forEach((chat, index) => {
          if (chat.phones.includes(`55${customer.whatsapp.replace(/[^\d]/g, '')}`)) {
            chats.push(chat);
          }
        });
      })

      setOriginalChats(chats);
      setFilterChats(chats);

      if (activeChat) {
        const chatUpdated = chats.find(chat => chat.id === activeChat.id);
        
        setActiveChat(chatUpdated || undefined);
      }

      if (auth.type === 'VENDOR' && !individualPerm.viewAllChatsManager) {
        setIsVendorMode(true);

        if (chats.length) setActiveChat(chats[0]);
      }
    }
  }, [auth.type, auth.userId, customer.whatsapp, settingsCtx.wppAccounts])

  useEffect(() => {
    if (!hasPermissionToUse) {
      setMessages(PLACEHOLDER_CHATS[0].messages);
      setMessagesCurrentPage(1);
      setMessagesTotalPages(1);
      return;
    }

    if (activeChat) {
      settingsCtx.wppAccounts.find(account => {
        return account.chats.some(chat => {
          if (chat.id === activeChat.id) {
            getWppAccountMessages({
              wppAccountId: account._id,
              chatId: activeChat.id,
              page: 1,
              sendRequest,
              auth,
            }).then((response: OmnichannelWppAccountMessagesResponse) => {
              setMessages(response.messages.reverse());
              setMessagesCurrentPage(1);
              setMessagesTotalPages(response.meta.totalPages);

              msgbox.current?.scrollIntoView(false);
            });

            return true;
          }

          return false;
        })
      });
    }
  }, [activeChat]);

  const handleLoadMorePastMessages = () => {
    const page = messagesCurrentPage + 1;

    if (activeChat && page <= messagesTotalPages) {
      settingsCtx.wppAccounts.find(account => {
        return account.chats.some(chat => {
          if (chat.id === activeChat.id) {
            getWppAccountMessages({
              wppAccountId: account._id,
              chatId: activeChat.id,
              page,
              sendRequest,
              auth,
            }).then((response: OmnichannelWppAccountMessagesResponse) => {
              setMessages([...response.messages.reverse(), ...messages]);
              setMessagesCurrentPage(page);
              setMessagesTotalPages(response.meta.totalPages);
            });

            return true;
          }

          return false;
        })
      });
    }
  };

  const renderContactListItemLastMessage = (message: OmnichannelDataChatMessage) => {
    if (message.type === WppAccountMessageType.AUDIO) return (
      <div className="omnichat__left-container__contact-list__item__details__resume__info">
        <PlayArrowRoundedIcon />
        {message.fromMe ? 'Você: ' : ''}Aúdio
      </div>
    );

    if (message.type === WppAccountMessageType.CHAT) return (
      `${message.fromMe ? 'Você: ' : ''}${message.content}`
    );

    if (message.type === WppAccountMessageType.IMAGE) return (
      <div className="omnichat__left-container__contact-list__item__details__resume__info">
        <PhotoCameraRoundedIcon />
        {message.fromMe ? 'Você: ' : ''}Foto
      </div>
    );

    if (message.type === WppAccountMessageType.PTT) return (
      <div className="omnichat__left-container__contact-list__item__details__resume__info">
        <MicRoundedIcon />
        {message.fromMe ? 'Você: ' : ''}Mensagem de voz
      </div>
    );

    return '...';
  };

  const renderContactListItem = (chat: OmnichannelDataChat) => {
    let lastMessageAt: string = '...';

    if (chat.messages.length) {
      const last = chat.messages[chat.messages.length -1];
      
      lastMessageAt = `${(new Date(last.timestamp * 1000)).toLocaleTimeString(['pt-BR'], {hour: '2-digit', minute: '2-digit'})}`;
    }

    return (
      <div className={`omnichat__left-container__contact-list__item ${activeChat?.id === chat.id && 'active'}`} key={`contact-item-${chat.id}`} onClick={() => setActiveChat(chat)}>
        <div className="omnichat__left-container__contact-list__item__avatar">
          <img src={chat.picture || 'https://picsum.photos/400/400'} alt="Contact Avatar" />
        </div>
        <div className="omnichat__left-container__contact-list__item__details">
          {chat.vendorName && (
            <div className="omnichat__left-container__contact-list__item__details__over">
              <Typography variant="body2">
                {chat.vendorName}
              </Typography>
            </div>
          )}
          <div className="omnichat__left-container__contact-list__item__details__header">
            <Typography variant="subtitle1">
              {chat.name}
            </Typography>
            <Typography variant="caption">
              {lastMessageAt}
            </Typography>
          </div>
          <div className="omnichat__left-container__contact-list__item__details__resume">
            <Typography variant="body2">
              {renderContactListItemLastMessage(chat.messages[chat.messages.length -1])}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderContactList = () => {
    return filterChats.map((chat, index) => {
      return renderContactListItem(chat);
    });
  };

  const renderMessageListItemByType = (message: OmnichannelDataChatMessage) => {
    if ([WppAccountMessageType.AUDIO, WppAccountMessageType.PTT].includes(message.type)) return (
      <div className={`omnichat__right-container__messages__container__row__card-audio ${message.fromMe && 'myself'}`}>
        {/* <Typography className="omnichat__right-container__messages__container__row__card__seller-name" variant="body1">
          Frederico Martinez
        </Typography> */}
        <ChatAudioPlayer 
          uuid={message.uuid} 
          src={message.content} 
          time={(new Date(message.timestamp * 1000)).toLocaleTimeString(['pt-BR'], {hour: '2-digit', minute: '2-digit'})} 
          withWave={message.type === WppAccountMessageType.PTT} 
          />
      </div>
    );

    if (message.type === WppAccountMessageType.IMAGE) return (
      <div className={`omnichat__right-container__messages__container__row__card-image ${message.fromMe && 'myself'}`}>
        {/* <Typography className="omnichat__right-container__messages__container__row__card__seller-name" variant="body1">
          Frederico Martinez
        </Typography> */}
        <img src={message.content} alt="" />
        <div className="omnichat__right-container__messages__container__row__card-image__info">
          <Typography variant="caption">
            {(new Date(message.timestamp * 1000)).toLocaleTimeString(['pt-BR'], {hour: '2-digit', minute: '2-digit'})}
          </Typography>
        </div>
      </div>
    );

    return (
      <div className={`omnichat__right-container__messages__container__row__card ${message.fromMe && 'myself'}`}>
        {/* <Typography className="omnichat__right-container__messages__container__row__card__seller-name" variant="body1">
          Frederico Martinez
        </Typography> */}
        <Typography variant="body2">
          {message.content}
        </Typography>
        <div className="omnichat__right-container__messages__container__row__card__info">
          <Typography variant="caption">
            {(new Date(message.timestamp * 1000)).toLocaleTimeString(['pt-BR'], {hour: '2-digit', minute: '2-digit'})}
          </Typography>
        </div>
      </div>
    );
  };

  const renderMessageListItem = (message: OmnichannelDataChatMessage) => {
    const key = `message-item-${message.uuid}`;

    return (
      <div className={`omnichat__right-container__messages__container__row ${message.fromMe && 'myself'}`} key={key} id={key}>
        {renderMessageListItemByType(message)}
      </div>
    );
  };

  const renderMessageListTime = (date: string) => {
    return (
      <div className="omnichat__right-container__messages__container__row date">
        <div className="omnichat__right-container__messages__container__row__date">
          <Typography variant="body2">
            {date}
          </Typography>
        </div>
      </div>
    );
  };

  const renderMessageList = () => {
    let currentDate: Date; 

    return messages.map((message, index) => {
      let renders = [];

      const messageDate = new Date(message.timestamp * 1000);

      if (currentDate === undefined || currentDate.toLocaleDateString() !== messageDate.toLocaleDateString()) {
        currentDate = messageDate;

        renders.push(renderMessageListTime(currentDate.toLocaleDateString('pt-BR')));
      }

      if (TREATED_MESSAGE_TYPES.includes(message.type)) {
        renders.push(renderMessageListItem(message));
      }

      return renders;
    });
  };

  const renderSearchMessageListItem = (message: OmnichannelDataChatMessage) => {
    const nowDate = new Date();
    const messageDate = new Date(message.timestamp * 1000);

    let messageAt = messageDate.toLocaleTimeString(['pt-BR'], {hour: '2-digit', minute: '2-digit'});

    if (nowDate.toLocaleDateString() !== messageDate.toLocaleDateString()) messageAt = messageDate.toLocaleDateString(['pt-BR'])

    return (
      <div className={`omnichat__search-container__message-list__item`} key={`search-message-item-${message.uuid}`} onClick={() => handleScrollToMessage(message)}>
        <div className="omnichat__search-container__message-list__item__details">
          <div className="omnichat__search-container__message-list__item__details__header">
            <Typography variant="caption">
              {messageAt}
            </Typography>
          </div>
          <div className="omnichat__search-container__message-list__item__details__resume">
            <Typography variant="body2">
              {`${message.fromMe ? 'Você: ' : ''}${message.content}`}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const renderSearchMessageList = () => {
    return searchMessages.map((message, index) => {
      return renderSearchMessageListItem(message);
    });
  };

  const handleChatSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let chats: OmnichannelDataChat[] = originalChats;

    if (event.target.value) {
      chats = chats.filter(chat => chat.name.includes(event.target.value));
    }

    setFilterChats(chats);
  }

  const handleMessageSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let messages: OmnichannelDataChatMessage[] = [];

    if (event.target.value.length > 1) {
      messages = activeChat.messages.filter(message => message.type === WppAccountMessageType.CHAT && message.content.includes(event.target.value)).reverse();
    }

    setSearchMessages(messages);
  }

  const handleScrollToMessage = (message: OmnichannelDataChatMessage) => {
    document.getElementById(`message-item-${message.uuid}`).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });

    handleCloseMessageSearch();
  };

  const handleBackToContactList = () => {
    setActiveChat(undefined);
  };

  const handleToggleMessageSearch = () => {
    if (showSearchContainer) setSearchMessages([]);
    
    setShowSearchContainer(!showSearchContainer);
  };

  const handleCloseMessageSearch = () => {
    setSearchMessages([]);
    setShowSearchContainer(false);
  };

  const hasShowLeftContainer = (): boolean => {
    if (isMobileMode) return !activeChat;

    return !isVendorMode;
  };

  const hasShowRightContainer = (): boolean => {
    if (isMobileMode) return !!activeChat && !showSearchContainer;

    return true
  };

  return (
    <Fragment>
      <div className="omnichat">
        {!hasPermissionToUse && <ModuleBlocker />}
        {hasShowLeftContainer() && (
          <div className="omnichat__left-container" style={isMobileMode ? {flexGrow: 1} : {}}>
            <div className="omnichat__left-container__header">
              <Typography className="omnichat__left-container__header__title" variant="h6">
                Conversas
              </Typography>
              <div className="omnichat__left-container__header__actions">
                <IconButton aria-label="actions" component="span" onClick={() => {}} disabled>
                    <MoreVertRoundedIcon />
                </IconButton>
              </div>
            </div>
            <div className="omnichat__left-container__search">
              <div className="omnichat__left-container__search__field">
                <div className="omnichat__left-container__search__field__preffix">
                  <SearchRoundedIcon />
                </div>
                <InputBase
                    className="omnichat__left-container__search__field__input"
                    placeholder="Pesquisar"
                    onChange={handleChatSearchOnChange}
                    inputProps={{ 'aria-label': 'escreva uma mensagem' }}
                  />
              </div>
              <div className="omnichat__left-container__search__right">
                <IconButton aria-label="action-send" component="span" onClick={() => {}} disabled>
                  <FilterListRoundedIcon />
                </IconButton>
              </div>
            </div>
            <div className="omnichat__left-container__contact-list">
              {renderContactList()}
            </div>
          </div>
        )}
        {hasShowRightContainer() && (
          <div className="omnichat__right-container">
            {!activeChat && (
              <>
                <div className="omnichat__right-container__nochat">
                  <div className="omnichat__right-container__nochat__note">
                    <div className="omnichat__right-container__nochat__note__icon">
                      <SpeakerNotesOffRoundedIcon />
                    </div>
                    <Typography className="omnichat__right-container__nochat__note__title" variant="h6">
                      Não há conversa ativa
                    </Typography>
                    <Typography className="omnichat__right-container__nochat__note__subtitle" variant="subtitle1">
                      Para visualizar as mensagens, por favor, selecione uma conversa.
                    </Typography>
                  </div>
                </div>
              </>
            )}
            {activeChat && (
              <>
                <div className="omnichat__right-container__header">
                  {isMobileMode && !isVendorMode && (
                    <div className="omnichat__right-container__header__back">
                      <IconButton aria-label="action-search" component="span" onClick={handleBackToContactList} >
                          <ArrowBackRoundedIcon />
                      </IconButton>
                    </div>
                  )}
                  <div className="omnichat__right-container__header__overview">
                    <div className="omnichat__right-container__header__overview__avatar">
                      <img src={activeChat.picture || 'https://picsum.photos/400/400'} alt="Contact Avatar" />
                    </div>
                    <div className="omnichat__right-container__header__overview__details">
                      <Typography className="omnichat__right-container__header__overview__details__chat-name" variant="subtitle1">
                        {activeChat.name}
                      </Typography>
                      <Typography variant="body2">
                        +{activeChat.phones[0]}
                      </Typography>
                    </div>
                  </div>
                  <div className="omnichat__right-container__header__actions">
                    <IconButton aria-label="action-search" component="span" onClick={handleToggleMessageSearch} >
                        <SearchRoundedIcon />
                    </IconButton>
                    {/* <IconButton aria-label="action-menu" component="span" onClick={() => {}} >
                        <MoreVertRoundedIcon />
                    </IconButton> */}
                  </div>
                </div>
                <div className="omnichat__right-container__messages">
                  <div className="omnichat__right-container__messages__container" ref={msgbox}>
                    {messagesCurrentPage < messagesTotalPages && (
                      <div className="omnichat__right-container__messages__container__intersection" ref={intersection}>
                        {/* {isIntersecting && ( */}
                          <div className="omnichat__right-container__messages__container__intersection__loader">
                            <CircularProgress size={20} />
                          </div>
                        {/* )} */}
                      </div>
                    )}
                    {renderMessageList()}
                  </div>
                </div>
                {/* <div className="omnichat__right-container__footer">
                    <div className="omnichat__right-container__footer__left">
                      <IconButton aria-label="action-attach" component="span" onClick={() => {}} disabled>
                        <AttachFileRoundedIcon />
                      </IconButton>
                    </div>
                    <div className="omnichat__right-container__footer__field">
                      <InputBase
                          className="omnichat__right-container__footer__field__input"
                          placeholder="Escreva sua mensagem"
                          inputProps={{ 'aria-label': 'escreva uma mensagem' }}
                          disabled
                        />
                    </div>
                    <div className="omnichat__right-container__footer__right">
                      <IconButton aria-label="action-send" component="span" onClick={() => {}} disabled>
                        <SendRoundedIcon />
                      </IconButton>
                    </div>
                </div> */}
              </>
            )}
          </div>
        )}
        {showSearchContainer && (
          <div className="omnichat__search-container" style={isMobileMode ? {flexGrow: 1} : {}}>
            <div className="omnichat__left-container__header">
              <Typography className="omnichat__left-container__header__title" variant="h6">
                Buscar Mensagens
              </Typography>
              <div className="omnichat__left-container__header__actions">
                <IconButton aria-label="actions" component="span" onClick={handleCloseMessageSearch} >
                    <CloseRoundedIcon />
                </IconButton>
              </div>
            </div>
            <div className="omnichat__search-container__input">
              <div className="omnichat__search-container__input__field">
                <div className="omnichat__search-container__input__field__preffix">
                  <SearchRoundedIcon />
                </div>
                <InputBase
                    className="omnichat__search-container__input__field__input"
                    onChange={handleMessageSearchOnChange}
                    autoFocus
                    inputProps={{ 'aria-label': 'escreva uma mensagem' }}
                  />
              </div>
            </div>
            <div className="omnichat__search-container__message-list">
              {renderSearchMessageList()}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
