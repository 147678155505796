export const getPricingCompIdFromName = (
  name:
    | "Valor Fixo"
    | "Percentual sobre Valor Final"
    | "Percentual sobre Serviço"
    | "Percentual sobre Lucro"
    | "Percentual sobre Kit"
    | "Valor por kWp"
    | "Valor por Módulo"
    | "Valor por Inversor"
    | "Valor por km"
    | "Comissão"
    | "Comissão Individual"
    | "Margem de Negociação"
) => {
  switch (name) {
    case "Valor Fixo":
      return "FIXED";

    case "Percentual sobre Valor Final":
      return "PERCENTAGE_SERVICE_AND_KIT";

    case "Percentual sobre Serviço":
      return "PERCENTAGE_SERVICE";

    case "Percentual sobre Kit":
      return "PERCENTAGE_KIT";

    case "Percentual sobre Lucro":
      return "PERCENTAGE_PROFIT";

    case "Valor por kWp":
      return "PER_KWP";

    case "Valor por Módulo":
      return "PER_MODULE";

    case "Valor por Inversor":
      return "PER_INVERTER";

    case "Valor por km":
      return "PER_KM";

    case "Comissão":
      return "COMMISSION";

    case "Comissão Individual":
      return "INDIVIDUAL_COMMISSION";

    case "Margem de Negociação":
      return "TRADING_MARGIN";

    case "Margem de Negociação":
      return "TRADING_MARGIN";

    default:
      return "FIXED";
  }
};
