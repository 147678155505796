export type UserStatus =
  | "CANCELLED"
  | "REJECTED"
  | "ACTIVE"
  | "EXPIRED"
  | "PENDING"
  | "ADMIN_CREATED"
  | "STARTED_SIGNUP"
  | "GEN_BILL"
  | "FIN_FOR_PIX"
  | "OWNER"
  | "SUPPORT";

export type UserPaymentMethod = "CREDIT_CARD" | "BILL" | "PIX";

export type ThemeColors =
  | "green"
  | "blue"
  | "amber"
  | "orange"
  | "red"
  | "grey"
  | "indigo"
  | "purple"
  | "deepPurple"
  | "teal"
  | "lime"
  | "lightGreen"
  | "cyan"
  | "deepOrange"
  | "brown";

export type UserTypeData = "VENDOR" | "MANAGER" | "INDEPENDENT";

export type SignupPlans =
  | "BASIC"
  | "CRM"
  | "ENTERPRISE"
  | "NOT_ADHERED"
  | "FREE"
  | "NONE";

export type StringNumbers =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17"
  | "18"
  | "19"
  | "20"
  | "21"
  | "22"
  | "23"
  | "24"
  | "25";

export type NumericNumbers =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15;

export type GeneralFilterOptions =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

export type OrderingIds =
  | "business_details"
  | "estimate_extra_1"
  | "estimate_extra_2"
  | "estimate_extra_3"
  | "template_extra_1"
  | "template_extra_2"
  | "template_extra_3"
  | "template_extra_4"
  | "template_extra_5"
  | "portfolio"
  | "how_it_works"
  | "benefits"
  | "step_by_step"
  | "project"
  | "pv_kit"
  | "system_info"
  | "services"
  | "fin_viability"
  | "fin_comparison"
  | "financing"
  | "last_remarks";

export type History = {
  push: (path: string, state?: unknown) => void;
};

export type PlanFilterOptions = "Iniciante" | "Profissional" | "Empresa";

export type Suppliers = "ECORI" | "ALDO" | "SOLAR INOVE";

export type OrderPlans = "BASIC" | "CRM" | "ENTERPRISE";

export type OrderType =
  | "SIGNUP"
  | "RENEWING"
  | "PLAN_UPGRADE"
  | "PLAN_DOWNGRADE"
  | "USERS_UPGRADE"
  | "USERS_DOWNGRADE"
  | "FREE_ACCESS";

export type OrderStatus =
  | "APPROVED"
  | "CANCELLED"
  | "REJECTED"
  | "PENDING"
  | "REFUNDED";

export type PricingComponentType =
  | "PER_KWP"
  | "PER_MODULE"
  | "PER_INVERTER"
  | "PERCENTAGE_SERVICE_AND_KIT"
  | "PERCENTAGE_SERVICE"
  | "PERCENTAGE_KIT"
  | "PERCENTAGE_PROFIT"
  | "FIXED"
  | "COMMISSION"
  | "INDIVIDUAL_COMMISSION"
  | "TRADING_MARGIN"
  | "PER_KM";

export type PricingRangeType =
  | "KWP"
  | "MODULES"
  | "KW"
  | "KM"
  | "INVERTERS"
  | "ROOF_HIGHT";

export type PricingConditionalType =
  | "VOLTAGE"
  | "FIXATION"
  | "INVERTER_TYPE"
  | "VENDOR"
  | "PRE_VENDOR";

export type PricingConditionalLogic = "EQUAL" | "DIFFERENT";

export type AzumeModules =
  | "Proposta 2.0"
  | "Proposta Personalizada"
  | "Assinatura Digital"
  | "Gerador de Propostas de Pós-Venda"
  | "Implementação Website"
  | "Implementação Domínio"
  | "Implementação em Grupo Conta"
  | "Implementação Exclusiva Conta";

export type AzumeWhitelabels = "SERRANA" | "";

export type AzumeModulesQueryParamIds = "PDF_2_0" | "IMP_1" | "IMP_2" | "IMP_3";

export type ImportTypes =
  | "Vendas"
  | "Kits"
  | "Clientes"
  | "Módulos"
  | "Inversores"
  | "Itens Complementares";

export type TimePeriodData = {
  timePeriodEarlierSelected: string;
  timePeriodOlderSelected: string;
};

export type AuthContextProps = {
  isLoggedIn: boolean;
  userIsAdmin: boolean;
  type: string;
  userId: string;
  managerId: string;
  managerUserId: string;
  vendors: { _id: string; email: string; personalData: { name: string } }[];
  token: string;
  statusOk: boolean;
  status: UserStatus;
  signupPlan: SignupPlans;
  activeModules: AzumeModules[];
  whitelabel: AzumeWhitelabels;
  login: (
    userId: string,
    managerId: string,
    managerUserId: string,
    vendors: { _id: string; email: string; personalData: { name: string } }[],
    uToken: string,
    type: string,
    uIsAdmin: boolean,
    uStatusOk: boolean,
    uStatus: UserStatus,
    signupPlan: SignupPlans,
    activeModules: AzumeModules[],
    whitelabel: AzumeWhitelabels,
    expirationDate?: Date
  ) => void;
  logout: () => void;
};

export type UserLoginProps = {
  isAdmin: boolean;
  userId: string;
  managerId: string;
  managerUserId: string;
  vendors: { _id: string; email: string; personalData: { name: string } }[];
  token: string;
  type: string;
  statusOk: boolean;
  status: UserStatus;
  signupPlan: SignupPlans;
  activeModules: AzumeModules[];
  whitelabel: AzumeWhitelabels;
  admAccess: boolean;
};

export type UserDataProps = {
  isAdmin: boolean;
  userId: string;
  managerId: string;
  managerUserId: string;
  vendors: { _id: string; email: string; personalData: { name: string } }[];
  uToken: string;
  uType: string;
  statusOk: boolean;
  status: UserStatus;
  signupPlan: SignupPlans;
  activeModules: AzumeModules[];
  whitelabel: AzumeWhitelabels;
  admAccess: boolean;
  expiration: string;
};

export type PlaceData = {
  name: string;
  lat: number;
  lng: number;
  uf: string;
};

export type Inputs = {
  [input: string]: {
    value: string | boolean | File | string[] | number;
    isValid: boolean;
    label?: string;
    forceError?: boolean;
  };
};

export interface FormHookDispState {
  inputs: Inputs;
  isValid: boolean;
  forceError?: boolean;
  reset?: boolean;
  reinitialize?: boolean;
}

export type AdminGeneralData = {
  user: {
    admin: string;
    password: string;
    name: string;
    logo: string;
    address: string;
    phone: string;
    whatsapp: string;
    website: string;
    facebook: string;
    instagram: string;
    cnpj: string;
    statusOk: boolean;
  };
  users: {
    id: string;
    email: string;
    status: UserStatus;
    personalData: {
      name: string;
      surname: string;
      document: string;
    };
    location: {
      phone: string;
    };
    name: string;
    logo: string;
    registryDate: string;
    lastPaymentDate: string;
    paymentDate: string;
    isTrial: boolean;
    signupPlan: SignupPlans;
    needPaymentBack: boolean;
    emailCharges: number;
    payments: {
      id: string;
      amount: number;
      date: string;
      method: UserPaymentMethod;
      lost: boolean;
    }[];
    customers: string[];
    proposals: string[];
  }[];
};

export type AdminUserData = {
  _id: string;
  id: string;
  email: string;
  status: UserStatus;
  personalData: {
    name: string;
    surname: string;
    document: string;
  };
  location: {
    phone: string;
  };
  name: string;
  logo: string;
  registryDate: string;
  lastPaymentDate: string;
  paymentDate: string;
  isTrial: boolean;
  signupPlan: SignupPlans;
  needPaymentBack: boolean;
  emailCharges: number;
  payments: {
    id: string;
    amount: number;
    date: string;
    method: UserPaymentMethod;
    lost: boolean;
  }[];
  customers: string[];
  proposals: string[];
};

export type CustomerData = {
  _id: string;
  id: string;
  email: string;
  name: string;
  uf: string;
  city: string;
  phone: string;
  whatsapp: string;
  cpfCnpjSwitch: boolean;
  document: string;
  profession: string;
  nationality: string;
  rg: string;
  address: string;
  registryDate: string;
  salesFunnelData: {
    stage: string;
    notes: {
      content: string;
      registryDate: string;
      warning: boolean;
      notificationDate: string;
      locator: string;
    }[];
    saleInProgressDate: string | undefined;
    saleClosedDate: string | undefined;
    saleLostDate: string | undefined;
    archived: boolean | undefined;
  };
  personInCharge: {
    name: string;
    cpf: string;
  };
  observations: string;
  origin: string;
  user: UserData;
  owners: UserData[] | string[];
  adminUser: string;
  proposals: string[];
};

export type CustomerDataPopEst = {
  _id: string;
  id: string;
  email: string;
  name: string;
  uf: string;
  city: string;
  phone: string;
  whatsapp: string;
  cpfCnpjSwitch: boolean;
  document: string;
  profession: string;
  nationality: string;
  rg: string;
  address: string;
  registryDate: string;
  salesFunnelData: {
    stage: string;
    notes: {
      content: string;
      registryDate: string;
      warning: boolean;
      notificationDate: string;
      locator: string;
    }[];
    saleInProgressDate: string | undefined;
    saleClosedDate: string | undefined;
    saleLostDate: string | undefined;
    archived: boolean | undefined;
  };
  personInCharge: {
    name: string;
    cpf: string;
  };
  observations: string;
  origin: string;
  user: UserData;
  owners: string[];
  adminUser: string;
  proposals: ProposalData[];
};

export type CustomerNamesAndPrice = {
  _id: string;
  id: string;
  email: string;
  name: string;
  proposals: {
    _id: string;
    count: number;
    realSystemData: { power: number; distance?: number };
    revenueData: { finalPrice: number };
    proposalCustomizationData: { validityDate: string };
  }[];
};

export type KitData = {
  _id: string;
  id: string;
  kitData: {
    kitSupplier: string;
    kitPower: number;
    kitPrice: number;
    kitOriginalPrice: number;
    kitVoltage?: number;
    kitFixation?: string;
  };
  pvModuleData: {
    model: string;
    manufacturer: string;
    power: number;
    warrantyDefect: number;
    warrantyEfficiency: number;
    quantity: number;
    weight?: number;
    mLength?: number;
    width?: number;
  }[];
  inverterData: {
    iManufacturer: string;
    iModel: string;
    iPower: number | null;
    iQuantity: number | null;
    iWarranty: number | null;
    iMonitoring: string;
    type: "inverter" | "microinverter";
  }[];
  additionalItems?: {
    item: string;
    quantity: string;
    manufacter: string;
    detail: string;
  }[];
  products: {
    modPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
    invPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      volt: number;
      price: number;
    }[];
    eqpPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
  };
  aboutTheEquipament?: string;
  gotInverter: boolean;
  registryDate: string;
  user: string;
  manager: string | undefined;
};

export type GeneratorData = {
  _id: string;
  id: string;
  externalId?: string;
  cartId: string;
  supplier: Suppliers;
  disableRemove?: boolean;
  genData: {
    genName: string;
    genSupplier: string;
    genPower: number;
    genPrice: number;
    genOriginalPrice?: number;
    genVoltage: number;
    genFixation?: string;
  };
  pvModuleData: {
    model: string;
    manufacturer: string;
    power: number;
    warrantyDefect: number;
    warrantyEfficiency: number;
    quantity: number;
    weight?: number;
    mLength?: number;
    width?: number;
  }[];
  inverterData: {
    id: string;
    iManufacturer: string;
    iModel: string;
    iPower: number | null;
    iQuantity: number | null;
    iWarranty: number | null;
    iMonitoring: string;
    type: "inverter" | "microinverter";
  }[];
  additionalItems: {
    id: string;
    item: string;
    quantity: string;
    manufacter: string;
    detail: string;
  }[];
  registryDate: string;
  registryTimestamp: number;
};

export type GeneralUserData = {
  customers: { _id: string; registryDate: string }[];
  proposals: {
    _id: string;
    registryDate: string;
  }[];
  kits: { _id: string; registryDate: string }[];
  sales: { _id: string; saleClosedDate: string }[];
  totalCustomers: number;
  totalProposals: number;
  totalKits: number;
  totalClosedSales: number;
  vendors: UserDataProps["vendors"];
  activeModules: AzumeModules[];
  whitelabel: string;
  user: UserData;
};

export type UserData = {
  _id: string;
  id: string;
  frontId: string;
  email: string;
  password: string;
  status: UserStatus;
  statusOk: boolean;
  adminCreated: boolean;
  companyPosition: string;
  personalData: {
    name: string;
    surname: string;
    document: string;
  };
  location: {
    postalCode: string;
    uf: string;
    city: string;
    address: string;
    district: string;
    number: string;
    complement: string;
    phone: string;
    ip: string;
  };
  cancelFeedback: {
    mainReason: string;
    secondaryReason: string;
    reasonToComeBack: string;
    otherReasonToComeBack: string;
    message: string;
  };
  personInCharge: {
    name: string;
    cpf: string;
    rg: string;
    profession: string;
    nationality: string;
  };
  name: string;
  logo: string;
  address: string;
  phone: string;
  whatsapp: string;
  website: string;
  facebook: string;
  instagram: string;
  cnpj: string;
  registryDate: string;
  lastPaymentDate: string;
  paymentDate: string;
  isTrial: boolean;
  signupPlan: SignupPlans;
  paymentId: number;
  cardLastFourNumbers: string;
  needPaymentBack: boolean;
  emailCharges: number;
  lastPaymentPrice: number;
  type: UserTypeData;
  payments: {
    id: string;
    amount: number;
    date: string;
    method: UserPaymentMethod;
    lost: boolean;
  }[];
  ordersWithRevenue: string[];
  customers: string[];
  proposals: string[];
  kits: string[];
  templates: string[];
  funnels: string[];
  manager?: ManagerData;
  activeOrder?: OrderData;
  activeSubOrder?: OrderData;
  orders?: OrderData[];
};

export type ProposalData = {
  _id: string;
  id: string;
  frontId: string;
  count?: number;
  customerData: {
    email: string;
    name: string;
    whatsapp: string;
    document: string;
  };
  userData: {
    name: string;
    logo: string;
    address: string;
    phone: string;
    whatsapp: string;
    website: string;
    facebook: string;
    instagram: string;
    cnpj: string;
    vendorName?: string;
    vendorPhone?: string;
    vendorEmail?: string;
  };
  location: {
    uf: string;
    city: string;
    address: string;
  };
  sundata: {
    location: {
      lat: number;
      lng: number;
    };
    irrMonthly: number[];
    irrAvg: number;
  };
  tariffModality: string;
  energyBillData: {
    uc: number;
    ucName: string;
    kwhPrice: number;
    kwhPricePeak?: number;
    tusd?: number;
    tusdPeak?: number;
    demand?: number;
    demandPeak?: number;
    demandTariff?: number;
    demandTariffPeak?: number;
    demandGen?: number;
    demandTariffTusdG?: number;
    classification?: "Azul" | "Verde";
    ucType: "Residencial" | "Comercial" | "Industrial" | "Rural" | "";
    correctionFactor?: number;
    networkClass: string;
    publicLightBill: number;
    monthlyConsumption: number[];
    monthlyConsumptionPeak?: number[];
    genPercentage: number;
    availabilityTaxDiscount: boolean;
    detailedTaxation: {
      detailedTaxation: boolean;
      simultaneityFactor: number;
      tusd: number;
      tusdPeak?: number;
      icms: number;
    };
    finViabilityData: {
      powerDistCompany: string;
      bWireValue: number;
      simultaneityFactor: number;
      showComparison: boolean;
      noSystemMonthlyBill: number;
      systemMonthlyBill: number;
    };
  }[];
  estimateSystemData: {
    dimensioningType?: "Consumo" | "Demanda";
    idealPower: number;
    targetPower?: number;
    idealPowerGenUc?: number;
    idealPowerNonPeak?: number;
    idealPowerPeak?: number;
    averageMontlhyConsumption: number;
    averageYearlyConsumption: number;
    averageMontlhyConsumptionPeak?: number;
    averageYearlyConsumptionPeak?: number;
    losses: number;
  };
  pvModuleData: {
    manufacturer: string;
    power: number;
    warrantyDefect: number;
    warrantyEfficiency: number;
    quantity: number;
    weight?: number;
    mLength?: number;
    width?: number;
  }[];
  inverterData: {
    iManufacturer: string;
    iModel: string;
    iPower: number;
    iQuantity: number;
    iWarranty: number;
    iMonitoring: string;
    type?: "inverter" | "microinverter";
  }[];
  additionalItems?: {
    item: string;
    manufacter: string;
    quantity: string;
    detail: string;
  }[];
  products: {
    modPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
    invPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      volt: number;
      price: number;
    }[];
    eqpPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
  };
  serranaCodeData?: {
    code?: string;
    hash?: string;
    activeWhitelabel?: boolean;
  };
  serranaPaymentData?: {
    method: string;
    intallments: string;
    holder: string;
  };
  serranaSystemData?: SerranaSuggestedKit;
  aboutTheEquipament?: string;
  gotInverter: boolean;
  realSystemData: {
    supplier: string;
    power: number;
    distance?: number;
    kitVoltage?: number;
    kitFixation?: string;
    averageMontlhyGeneration: number;
    averageYearlyGeneration: number;
    minArea: number;
    distributedWeight: number;
    monthyGeneration: number[];
  };
  revenueData: {
    pvKitCost: number;
    pvKitVendorFrontCost: number;
    pvKitOriginalCost: number;
    servicePricePerkWp: number;
    serviceCosts: { name: string; value: number; isRevenue: boolean }[];
    percentageComponents: {
      name: string;
      value: number;
      type: PricingComponentType;
      isRevenue: boolean;
    }[];
    tradingMargin: number;
    discount: number;
    nfValue: number;
    taxes: number;
    inflation: number;
    marginEquip: number;
    margin: number;
    totalEqp: number | null;
    totalSvc: number | null;
    totalPerKwp: number | null;
    equipamentProfit: number;
    totalCost: number;
    liquidServices: number;
    costPerKwp: number;
    profit: number;
    profitPerKwp: number;
    finalPrice: number;
    paymentConditions: string;
    finantialViability: {
      firstYearNoSystem: number[];
      nonPeakMonthyCost?: number;
      nonPeakDemandCost?: number;
      peakMonthyCost?: number;
      peakDemandCost?: number;
      genDemandCost?: number;
      firstYearWithSystem: number;
      nonPeakMonthyEconomy?: number;
      peakMonthyEconomy?: number;
      systemCashFlow: number[];
      paybackYears: number;
      paybackMonths: number;
      totalDiscountedEconomy: number;
      roi: number;
      tir: number;
      vpl: number;
      lcoe: number;
      yearlyMinEnergyBill?: number[];
      yearlyEconomy?: number[];
      yearlyNoSystemBill?: number[];
      yearlySystemBill?: number[];
    };
    oldFinantialViability: {
      firstYearNoSystem: number[];
      firstYearWithSystem: number | null;
      systemCashFlow: number[];
      paybackYears: number | null;
      paybackMonths: number | null;
      totalDiscountedEconomy: number | null;
      roi: number | null;
    };
    financing: {
      finOptId?: string;
      imageUrl?: string;
      bank: string;
      entry: number;
      insurance: boolean;
      entryInstallments: number;
      gracePeriod: number;
      options: {
        installments: number;
        value: number;
        insuranceValue: number;
      }[];
    }[];
  };
  proposalCustomizationData: {
    validity: number;
    validityDate: string;
    validityFlag: string;
    theme: StringNumbers | NumericNumbers;
    color1: string;
    color2: string;
    extraSection1?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
    extraSection2?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
    extraSection3?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
  };
  totalMonthlyConsumption: number[];
  registryDate: string;
  pdf: string;
  lastViewTimestamp: number;
  finished: boolean;
  views?: number;
  customer: string;
  sale: SaleData | string;
  user?: UserData;
  owners: string[];
  generation: string;
  docxUrl: string;
};

export type ProposalDataPopCust = {
  _id: string;
  id: string;
  frontId: string;
  count?: number;
  customerData: {
    email: string;
    name: string;
    whatsapp: string;
    document: string;
  };
  userData: {
    name: string;
    logo: string;
    address: string;
    phone: string;
    whatsapp: string;
    website: string;
    facebook: string;
    instagram: string;
    cnpj: string;
    vendorName?: string;
    vendorPhone?: string;
    vendorEmail?: string;
  };
  location: {
    uf: string;
    city: string;
    address: string;
  };
  sundata: {
    location: {
      lat: number;
      lng: number;
    };
    irrMonthly: number[];
    irrAvg: number;
  };
  tariffModality: string;
  energyBillData: {
    uc: number;
    ucName: string;
    kwhPrice: number;
    kwhPricePeak?: number;
    tusd?: number;
    tusdPeak?: number;
    demand?: number;
    demandPeak?: number;
    demandTariff?: number;
    demandTariffPeak?: number;
    demandGen?: number;
    demandTariffTusdG?: number;
    classification?: "Azul" | "Verde";
    ucType: "Residencial" | "Comercial" | "Industrial" | "Rural" | "";
    correctionFactor?: number;
    networkClass: string;
    publicLightBill: number;
    monthlyConsumption: number[];
    monthlyConsumptionPeak?: number[];
    genPercentage: number;
    availabilityTaxDiscount: boolean;
    detailedTaxation: {
      detailedTaxation: boolean;
      simultaneityFactor: number;
      tusd: number;
      tusdPeak?: number;
      icms: number;
    };
    finViabilityData: {
      powerDistCompany: string;
      bWireValue: number;
      simultaneityFactor: number;
      showComparison: boolean;
      noSystemMonthlyBill: number;
      systemMonthlyBill: number;
    };
  }[];
  estimateSystemData: {
    dimensioningType?: "Consumo" | "Demanda";
    idealPower: number;
    targetPower?: number;
    idealPowerNonPeak?: number;
    idealPowerPeak?: number;
    averageMontlhyConsumption: number;
    averageYearlyConsumption: number;
    averageMontlhyConsumptionPeak?: number;
    averageYearlyConsumptionPeak?: number;
    losses: number;
  };
  pvModuleData: {
    manufacturer: string;
    power: number;
    warrantyDefect: number;
    warrantyEfficiency: number;
    quantity: number;
    weight?: number;
    mLength?: number;
    width?: number;
  }[];
  inverterData: {
    iManufacturer: string;
    iModel: string;
    iPower: number;
    iQuantity: number;
    iWarranty: number;
    iMonitoring: string;
    type?: "inverter" | "microinverter";
  }[];
  additionalItems?: {
    item: string;
    manufacter: string;
    quantity: string;
    detail: string;
  }[];
  products: {
    modPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
    invPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      volt: number;
      price: number;
    }[];
    eqpPriceUnits: {
      id: string;
      idx: number;
      value: number;
      qtd: number;
      price: number;
    }[];
  };
  serranaCodeData?: {
    code?: string;
    hash?: string;
    activeWhitelabel?: boolean;
  };
  serranaPaymentData?: {
    method: string;
    intallments: string;
    holder: string;
  };
  serranaSystemData?: SerranaSuggestedKit;
  aboutTheEquipament?: string;
  gotInverter: boolean;
  realSystemData: {
    supplier: string;
    power: number;
    distance?: number;
    kitVoltage?: number;
    kitFixation?: string;
    averageMontlhyGeneration: number;
    averageYearlyGeneration: number;
    minArea: number;
    distributedWeight: number;
    monthyGeneration: number[];
  };
  revenueData: {
    pvKitCost: number;
    pvKitVendorFrontCost: number;
    pvKitOriginalCost: number;
    servicePricePerkWp: number;
    serviceCosts: { name: string; value: number; isRevenue: boolean }[];
    percentageComponents: {
      name: string;
      value: number;
      type: PricingComponentType;
      isRevenue: boolean;
    }[];
    tradingMargin: number;
    discount: number;
    nfValue: number;
    taxes: number;
    inflation: number;
    marginEquip: number;
    margin: number;
    totalEqp: number | null;
    totalSvc: number | null;
    totalPerKwp: number | null;
    equipamentProfit: number;
    totalCost: number;
    liquidServices: number;
    costPerKwp: number;
    profit: number;
    profitPerKwp: number;
    finalPrice: number;
    paymentConditions: string;
    finantialViability: {
      firstYearNoSystem: number[];
      nonPeakMonthyCost?: number;
      nonPeakDemandCost?: number;
      peakMonthyCost?: number;
      peakDemandCost?: number;
      genDemandCost?: number;
      firstYearWithSystem: number;
      nonPeakMonthyEconomy?: number;
      peakMonthyEconomy?: number;
      systemCashFlow: number[];
      paybackYears: number;
      paybackMonths: number;
      totalDiscountedEconomy: number;
      roi: number;
      tir: number;
      vpl: number;
      lcoe: number;
      yearlyMinEnergyBill?: number[];
      yearlyEconomy?: number[];
      yearlyNoSystemBill?: number[];
      yearlySystemBill?: number[];
    };
    oldFinantialViability: {
      firstYearNoSystem: number[];
      firstYearWithSystem: number | null;
      systemCashFlow: number[];
      paybackYears: number | null;
      paybackMonths: number | null;
      totalDiscountedEconomy: number | null;
      roi: number | null;
    };
    financing: {
      finOptId?: string;
      imageUrl?: string;
      bank: string;
      entry: number;
      insurance: boolean;
      entryInstallments: number;
      gracePeriod: number;
      options: {
        installments: number;
        value: number;
        insuranceValue: number;
      }[];
    }[];
  };
  proposalCustomizationData: {
    validity: number;
    validityDate: string;
    validityFlag: string;
    theme: StringNumbers | NumericNumbers;
    color1: string;
    color2: string;
    extraSection1?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
    extraSection2?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
    extraSection3?: {
      image: string | null;
      title: string;
      removeImage: boolean;
      paragraph1: string;
      paragraph2: string;
      paragraph3: string;
      disabled: boolean;
    };
  };
  totalMonthlyConsumption: number[];
  registryDate: string;
  pdf: string;
  lastViewTimestamp: number;
  finished: boolean;
  views?: number;
  customer: string | { _id: string };
  sale: SaleData | string;
  user?: UserData;
  owners: string[];
  generation: string;
  docxUrl: string;
};

export type TemplateData = {
  order: OrderingIds[];
  pdfCover: {
    complete: "simple" | "geometric" | "modern" | "technology" | "custom";
    print: "simple" | "geometric" | "modern" | "technology" | "custom";
    customCoverUrl: string;
  };
  pdfHeaderFooter: {
    header: string;
    disableHeader: boolean;
    footer: string;
    disableFooter: boolean;
  };
  watermarkAndSeal: {
    watermark: string;
    removeWatermark: boolean;
    seal: string;
    removeSeal: boolean;
  };
  companySignature: {
    enabled: boolean;
  };
  font: string;
  fontSize: "small" | "medium" | "large";
  section1HowItWorks: {
    image: string;
    title: string;
    step1: string;
    step2: string;
    step3: string;
    step4: string;
    step5: string;
    step6: string;
    step7: string;
    disabled: boolean;
    pdf: boolean;
    originalImage: boolean;
  };
  section2Benefits: {
    title: string;
    benefit1: string;
    benefit2: string;
    benefit3: string;
    benefit4: string;
    benefit5: string;
    benefit6: string;
    disabled: boolean;
    pdf: boolean;
  };
  section3StepByStep: {
    title: string;
    disabled: boolean;
    pdf: boolean;
  };
  section4Project: {
    title: string;
    text: string;
  };
  ucsEnergyPerc: {
    disabled: boolean;
    pdf: boolean;
  };
  section5PVKit: {
    image: string;
    removeImage: boolean;
  };
  section6Services: {
    title: string;
    service1: string;
    service2: string;
    service3: string;
    service4: string;
    service5: string;
    service6: string;
    service7: string;
    service8: string;
    service9: string;
    service10: string;
    finalRemark: string;
    disabled: boolean;
    pdf: boolean;
  };
  section7FinViability: {
    finComparisonName1: string;
    interestRate1: number;
    finComparisonName2: string;
    interestRate2: number;
    disabled: boolean;
    pdf: boolean;
  };
  detailedFinancing: {
    disabled: boolean;
    pdf: boolean;
  };
  energyBillProjection: {
    disabled: boolean;
    pdf: boolean;
  };
  section8LastRemarks: {
    title: string;
    remark1: string;
    remark2: string;
    remark3: string;
    remark4: string;
    remark5: string;
    remark6: string;
    disabled: boolean;
    pdf: boolean;
  };
  section9BusinessDetails: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section10Extra1: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section11Extra2: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section13Extra3: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section14Extra4: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section15Extra5: {
    image: string;
    title: string;
    removeImage: boolean;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    disabled: boolean;
    pdf: boolean;
  };
  section12Portfolio: {
    project1: {
      image: string;
      title: string;
    };
    project2: {
      image: string;
      title: string;
    };
    project3: {
      image: string;
      title: string;
    };
    project4: {
      image: string;
      title: string;
    };
    project5: {
      image: string;
      title: string;
    };
    project6: {
      image: string;
      title: string;
    };
    disabled: boolean;
    pdf: boolean;
  };
  pdfTemplates: {
    _id: string;
    id: string;
    name: string;
    url: string;
    type?: "SALES" | "AFTER_SALES";
  }[];
  user: string;
};

export type MonthlyGenerationData = {
  month: string;
  generation: number;
  consumption: number;
  firstYearWithSystem: number;
  firstYearNoSystem: number;
};

export type CashFlowData = {
  year: string;
  flow: number;
};

export type CashFlowCompData = {
  year: string;
  flow1: number;
  flow2: number;
  flow3: number;
};

export type PaymentsPageCardTransactionResponseData = {
  signupStepThreeInfo: { document: string; cpfCnpjSwitch: boolean };
  newStatus: { statusOk: boolean; status: UserStatus };
};

export type CalcDataProposalStepThree = {
  eqpNoMarkup: number;
  markupEqp: number;
  eqpWithMarkup: number;
  eqpRev: number;
  svcNoMarkup: number;
  markupSvc: number;
  svcWithMarkup: number;
  svcRev: number;
  totalNoMarkup: number;
  markupTotal: number;
  totalWithMarkup: number;
  totalWithMarkupMaxMargin: number;
  totalWithMarkupMinMargin: number;
  preTaxProfit?: number;
  profit: number;
  totalPerKwp: number;
  profitPerKwp: number;
};

export type EasyPricingMarksData = {
  value: number;
  label: string;
}[];

export type KanbanData = {
  _id: string;
  id: string;
  columns: {
    _id: string;
    id: string;
    title: string;
    order: number;
    inactivityWindow?: number;
  }[];
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  manager: string;
  user: string;
  checklist?: ChecklistData;
};

export type KanbanFilledData = {
  kanban: KanbanData;
  items: SalePopCustData[];
};

export type KanbanFilledDataResp = {
  kanban: KanbanData;
  items: SalePopCustData[];
  hasMoreItems: boolean;
};

export type SignupStepOneInfoData = {
  name: string;
  surname: string;
  email: string;
  phone: string;
};

export type SignupStepOneInfoResponseData = {
  signupStepOneInfo: {
    name: string;
    surname: string;
    email: string;
    phone: string;
  };
};

export type SignupStepTwoInfoData = {
  postalCode: string;
  number: string;
  complement: string;
  phone: string;
};

export type SignupStepTwoInfoResponseData = {
  signupStepTwoInfo: {
    postalCode: string;
    number: string;
    complement: string;
    phone: string;
  };
};

export type PostalCodeResponseData = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  formatedAddress: string;
};

export type SignupStepThreeInfoData = {
  document: string;
  cpfCnpjSwitch: boolean;
};

export type SignupStepThreeInfoResponseData = {
  signupStepThreeInfo: {
    document: string;
    cpfCnpjSwitch: boolean;
  };
  qrCodeBase64?: string;
  finalPrice?: number;
  billLink?: string;
};

export type CouponData = {
  locator: string;
  name: string;
  discount: number;
  isValid: boolean;
  validityDate: string;
  registryDate: string;
};

export type MercadoPagoSDKProps = {
  getIdentificationTypes: () => Promise<
    {
      id: string;
      name: string;
      type: string;
      min_length: number;
      max_length: number;
    }[]
  >;
  getPaymentMethods: (props: { bin: string }) => Promise<{
    paging: {
      total: number;
      limit: number;
      offset: number;
    };
    results: {
      secure_thumbnail: string;
      min_accreditation_days: number;
      max_accreditation_days: number;
      id: string;
      payment_type_id: string;
      accreditation_time: number;
      thumbnail: string;
      marketplace: string;
      deferred_capture: string;
      labels: string[];
      name: string;
      site_id: string;
      processing_mode: string;
      additional_info_needed: string[];
      status: string;
      settings: {
        security_code: {
          mode: string;
          card_location: string;
          length: number;
        };
        card_number: {
          length: number;
          validation: string;
        };
        bin: {
          pattern: string;
          installments_pattern: string;
          exclusion_pattern: string;
        };
      }[];
    }[];
    issuer: {
      default: boolean;
      name: string;
      id: number;
    };
  }>;
  getIssuers: (props: { paymentMethodId: string; bin: string }) => Promise<
    {
      id: string;
      name: string;
      secure_thumbnail: string;
      thumbnail: string;
      processing_mode: string;
      merchant_account_id?: string;
    }[]
  >;
  getInstallments: (props: {
    amount: string;
    locale: string;
    bin: string;
    processingMode: "aggregator" | "gateway";
  }) => Promise<
    {
      merchant_account_id?: string;
      payment_method_id: string;
      payment_type_id: string;
      processing_mode: "aggregator" | "gateway";
      issuer: {
        id: string;
        name: string;
        secure_thumbnail: string;
        thumbnail: string;
      };
      payer_costs: {
        installments: number;
        installment_rate: number;
        discount_rate: number;
        labels: string[];
        installment_rate_collector: string[];
        min_allowed_amount: number;
        max_allowed_amount: number;
        recommended_message: string;
        installment_amount: number;
        total_amount: number;
        payment_method_option_id: string;
      }[];
    }[]
  >;
  createCardToken: (props: {
    cardNumber: string;
    cardholderName: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    securityCode: string;
    identificationType: "CPF" | "CNPJ";
    identificationNumber: string;
  }) => Promise<{
    id: string;
  }>;
};

export type MercadoPagoCardInfo = {
  paymentMethodId: string;
  issuerId: string;
  token: string;
};

export type SignupPricesAndTaxesData = {
  simple: number;
  crm: number;
  enterprise: number;
  enterprise5: number;
  enterprise10: number;
  enterprise20: number;
  prop20: number;
  customProp: number;
  digSign20: number;
  digSign50: number;
  digSign100: number;
  webImp: number;
  domImp: number;
  premiumConfig: number;
  standardConfig: number;
  planUpgrade: number;
  crmRec: number;
  enterprise2EmpRec: number;
  enterprise5EmpRec: number;
  enterprise10EmpRec: number;
  enterprise20EmpRec: number;
  enterprise50EmpRec: number;
  installmentsTaxes: number[];
  addons: [{
    type: AddonType;
    items: [{
      type: AddonOmnichannelType;
      price: number;
    }]
  }]
};

export type UpdateData = {
  id: string;
  version: string;
  status: "CRITICAL" | "NEW_FUNCTIONALITY" | "BUG_FIX";
  info: string;
  registryDate: string;
  registryTimestamp: number;
};

export type PVModuleData = ProposalData["pvModuleData"][0];

export type NotificationData = {
  _id: string;
  id: string;
  title: string;
  content: string;
  openUrl: string;
  type: "ADMIN" | "PUBLIC" | "PRIVATE";
  registryDate: string;
  registryTimestamp: number;
  user: string;
};

export type ManagerData = {
  id: string;
  registryDate: string;
  registryTimestamp: number;
  vendorsAmount: number;
  orders: string[];
  vendors: string[];
  user: string;
};

export type OrderData = {
  _id: string;
  id: string;
  amount: number;
  discount: number;
  discountedAmount: number;
  method: UserPaymentMethod;
  plan: OrderPlans;
  type: OrderType;
  bonusTrialPurchase: boolean;
  installments: number;
  status: OrderStatus;
  registryDate: string;
  registryTimestamp: number;
  validityDate: string;
  validityTimestamp: number;
  cancelDate: string;
  cancelTimestamp: number;
  addedVendors: number;
  addedModules: string[];
  addedSignatures: number;
  signaturesValidity: string;
  needsReversal: boolean;
  cancelledAfterUsed: boolean;
  transactionId: number;
  orderId: string;
  subscriptionId: string;
  isPagarMe20?: boolean;
  isRecurrence?: boolean;
  freeInstallments?: number;
  src: string;
  metaData: {
    newModules: AzumeModules[];
    newSignatures: number;
    newUsers: number;
    planUpgrade: boolean;
  };
  admin: string;
  user: string;
  manager: string;
};

export type SaleData = {
  _id: string;
  id: string;
  amount: number;
  name: string;
  stage: string;
  status: "IN_PROGRESS" | "CLOSED" | "LOST";
  execStatus?: "INTERNAL" | "OUTSOURCED";
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  leadTemperature?: 0 | 1 | 2;
  saleLostReason: string;
  rejectReasons: {
    _id: string;
    id: string;
    reason: string;
    registryDate: string;
    registryTimestamp: number;
  }[];
  saleInProgressDate: string | null;
  saleInProgressTimestamp: number | null;
  saleClosedDate: string | null;
  saleClosedTimestamp: number | null;
  saleLostDate: string | null;
  saleLostTimestamp: number | null;
  archived: boolean;
  kanbanData: {
    column: string;
    order: number;
    finishedColumn: string;
  };
  validityDate: string;
  validityTimestamp: number;
  registryDate: string;
  registryTimestamp: number;
  lastActivityDate: string;
  lastActivityTimestamp: number;
  contracts: SaleContractData[];
  lastViewTimestamp: number;
  systemSummary: {
    power: number;
    ucType: "Residencial" | "Comercial" | "Industrial" | "Rural" | "";
  };
  afterSalesData?: {
    serviceOrder: number;
    uf: string;
    city: string;
    distance: number;
    power: number;
    modsQtd: number;
    invQtd: number;
    charger: string;
    inverterType: "String" | "Micro";
    services: {
      _id: string;
      id: string;
      service: string;
      serviceName: string;
      price: number;
      priceComps: string[];
      quantity: number;
      type?: "SERVICE" | "SUPPLY";
    }[];
  };
  user: {
    _id: string;
    id: string;
    statusOk: boolean;
    personalData: UserData["personalData"];
  };
  approvedProposal: ProposalData | string;
  relatedSale: SaleData | string | null;
  relatedPreSale: SaleData | string | null;
  customer: string;
  manager: string;
  funnel: string;
  kanban: string;
  notes: NoteData[] | string[];
  owners: string[];
};

export type SalePopCustData = {
  _id: string;
  id: string;
  amount: number;
  name: string;
  stage: string;
  status: "IN_PROGRESS" | "CLOSED" | "LOST";
  execStatus?: "INTERNAL" | "OUTSOURCED";
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  leadTemperature?: 0 | 1 | 2;
  saleLostReason: string;
  rejectReasons: {
    _id: string;
    id: string;
    reason: string;
    registryDate: string;
    registryTimestamp: number;
  }[];
  saleInProgressDate: string | null;
  saleInProgressTimestamp: number | null;
  saleClosedDate: string | null;
  saleClosedTimestamp: number | null;
  saleLostDate: string | null;
  saleLostTimestamp: number | null;
  archived: boolean;
  kanbanData: {
    column: string;
    order: number;
    finishedColumn: string;
  };
  validityDate: string;
  validityTimestamp: number;
  registryDate: string;
  registryTimestamp: number;
  lastActivityDate: string;
  lastActivityTimestamp: number;
  contracts: SaleContractData[];
  lastViewTimestamp: number;
  systemSummary: {
    power: number;
    ucType: "Residencial" | "Comercial" | "Industrial" | "Rural" | "";
  };
  afterSalesData?: {
    serviceOrder: number;
    uf: string;
    city: string;
    distance: number;
    power: number;
    modsQtd: number;
    invQtd: number;
    charger: string;
    roofHight: number;
    inverterType: "String" | "Micro";
    services: {
      _id: string;
      id: string;
      service: string;
      serviceName: string;
      price: number;
      quantity: number;
      priceComps: string[];
      type?: "SERVICE" | "SUPPLY";
    }[];
  };
  user: {
    _id: string;
    id: string;
    statusOk: boolean;
    personalData: UserData["personalData"];
  };
  approvedProposal: ProposalData | string;
  relatedSale: SaleData | string | null;
  relatedPreSale: SaleData | string | null;
  customer: CustomerDataPopEst;
  manager: string;
  funnel: string;
  kanban: string;
  notes: NoteData[] | NotePopCustData[] | string[];
  owners: string[];
  checklist?: ChecklistData;
};

export type NoteData = {
  _id: string;
  id: string;
  content: string;
  type: string;
  warning: boolean;
  notificationDate: string;
  notificationTimeStart: string;
  notificationTimeEnd: string;
  notificationTimestamp: number | null;
  registryDate: string;
  registryTimestamp: number;
  isPrivate?: boolean;
  archived: boolean;
  user: string;
  customer: string;
  sale: string;
  manager: string | null;
  funnel: string;
  owners: string[];
};

export type NotePopCustData = {
  _id: string;
  id: string;
  content: string;
  type: string;
  warning: boolean;
  notificationDate: string;
  notificationTimeStart: string;
  notificationTimeEnd: string;
  notificationTimestamp: number | null;
  registryDate: string;
  registryTimestamp: number;
  isPrivate?: boolean;
  archived: boolean;
  user: string;
  customer: {
    _id: string;
    id: string;
    name: string;
    phone: string;
    whatsapp: string;
  };
  sale: string | SaleData;
  manager: string | null;
  funnel: string;
  owners: string[];
};

export type NavContextProps = {
  activeView: number;
  activeUrl: string;
  navigateHandler: (url: string) => void;
};

export type NavContextData = {
  activeView: number;
  activeUrl: string;
};

export type SettingData = {
  _id: string;
  id: string;
  addons: AddonItem[];
  origins: string[];
  salesLostReasons: string[];
  rejectReasons: string[];
  noteTypes: string[];
  companyPositions: string[];
  stdFolders: string[];
  disabledSuppliers: string[];
  afterSalesServices: {
    _id: string;
    name: string;
    type?: "SERVICE" | "SUPPLY";
  }[];
  autoPricingType: "SIMPLE" | "DETAILED";
  autoPricingCalc: "MARKUP" | "GROSS_MARGIN";
  autoPricing: {
    power: number;
    margin: number;
  }[];
  preferences: {
    proposalGenStdValues: {
      losses: number;
      availabilityTaxDiscount: boolean;
      validity: number;
      inflation: number;
      powerDistCompany: string;
      hidePropNumber: number;
      simultFactors: {
        residential: number;
        commercial: number;
        industrial: number;
      };
      stdKitDiscount: number;
    };
    vendors: {
      useManagerKits: boolean;
      maxKitDiscount: number;
      stdOwners: string[];
    };
    proposalPDF: {
      useFastPDFRendering: boolean;
    };
    simulator: {
      distributeLeads: boolean;
    };
    kanbans: {
      usePreSalesKanban: boolean;
      autoCreateProjAndInst: boolean;
    };
    customers: {
      reqEmailField: boolean;
      reqDocField: boolean;
    };
    imports: {
      window: number;
    };
  };
  autoPricingDetailed: {
    autoPricingCalc: "MARKUP" | "GROSS_MARGIN";
    restrictions: {
      _id: string;
      id: string;
      name: string;
      locations?: string[];
      vendors?: string[];
      service?: string;
    }[];
    pricingComponents: {
      _id: string;
      id: string;
      name: string;
      type: PricingComponentType;
      range: PricingRangeType;
      conditionals: {
        type: PricingConditionalType;
        logic: PricingConditionalLogic;
        numValue?: number;
        strValue?: string;
      }[];
      isRevenue?: boolean;
      vendor?: string;
      service?: string;
      restriction?: string;
      discountFromComission?: boolean;
      proportion?: number;
      rules: {
        _id: string;
        id: string;
        vendor?: string;
        power: number;
        value: number;
      }[];
      negotiationMargins: {
        _id: string;
        id: string;
        power: number;
        value: number;
      }[];
    }[];
  };
  autoFinancing: {
    banks: {
      _id: string;
      id: string;
      name: string;
      imageUrl: string;
      finOpts: {
        _id: string;
        id: string;
        name: string;
        type: "PRICE" | "SAC";
        includeAsStd: boolean;
        installments: number;
        entryPerc: number;
        entryTotal?: number;
        interestRate: number;
        gracePeriod: number;
        insurance: boolean;
      }[];
    }[];
  };
  permissions: {
    vendorPriceEdit: boolean;
  };
  individualPermissions: {
    _id: string;
    id: string;
    salesKanban: boolean;
    projectKanban: boolean;
    installationKanban: boolean;
    afterSalesKanban: boolean;
    preSalesKanban: boolean;
    administrativeKanban: boolean;
    proposalsList: boolean;
    customersList: boolean;
    pricingTab: boolean;
    pricingEdit: boolean;
    salesManager: boolean;
    installationManager: boolean;
    projectManager: boolean;
    afterSalesManager: boolean;
    preSalesManager: boolean;
    administrativeManager: boolean;
    configManager: boolean;
    contractManager: boolean;
    kitsManager: boolean;
    employeesManager: boolean;
    viewAllChatsManager: boolean;
    user: string;
  }[];
  teams: {
    _id: string;
    id: string;
    name: string;
    leader: string;
    members: string[];
    type:
      | "SALES"
      | "PROJECT"
      | "INSTALLATION"
      | "AFTER_SALES"
      | "PRE_SALES"
      | "ADMINISTRATIVE";
    registryDate: string;
    registryTimestamp: number;
    lastModifiedDate: string;
    lastModifiedTimestamp: number;
  }[];
  suppliers: {
    fortlevAuth: {
      email: string;
      password: string;
    };
  };
  webhooks: {
    _id: string;
    id: string;
    name: string;
    uri: string;
    actions: WebhookAction[];
    secret: string;
    registryDate: string;
    registryTimestamp: number;
    lastModifiedDate: string;
    lastModifiedTimestamp: number;
  }[];
  proposalSlug: string;
  user: string;
  manager: string | null;
};

export type SettingsContextProps = {
  origins: string[];
  salesLostReasons: string[];
  rejectReasons: string[];
  notesTypes: string[];
  companyPositions: string[];
  stdFolders: string[];
  disabledSuppliers: string[];
  afterSalesServices: {
    _id: string;
    name: string;
    type?: "SERVICE" | "SUPPLY";
  }[];
  addons: SettingData["addons"];
  autoPricingType: SettingData["autoPricingType"];
  autoPricingCalc: SettingData["autoPricingCalc"];
  autoPricing: SettingData["autoPricing"];
  preferences: SettingData["preferences"];
  autoPricingDetailed: SettingData["autoPricingDetailed"];
  autoFinancing: SettingData["autoFinancing"];
  permissions: SettingData["permissions"];
  individualPermissions: SettingData["individualPermissions"];
  teams: SettingData["teams"];
  suppliers: SettingData["suppliers"];
  webhooks: SettingData["webhooks"];
  wppAccounts: OmnichannelData["wppAccounts"];
  proposalSlug: string;
  updateOriginsHandler: (newOrigins: string[]) => void;
  updateSalesLostReasonsHandler: (newReasons: string[]) => void;
  updateRejectReasonsHandler: (newReasons: string[]) => void;
  updateNotesTypesHandler: (newTypes: string[]) => void;
  updateCompanyPositionsHandler: (newPositions: string[]) => void;
  updateStdFoldersHandler: (newFolders: string[]) => void;
  updateDisabledSuppliersHandler: (suppliers: string[]) => void;
  updateAfterSalesServicesHandler: (
    services: SettingsContextData["afterSalesServices"]
  ) => void;
  updateAutoPricingTypeHandler: (
    newAutoPricingType: SettingData["autoPricingType"]
  ) => void;
  updateAutoPricingCalcHandler: (
    newAutoPricingCalc: SettingData["autoPricingCalc"]
  ) => void;
  updateAutoPricingHandler: (
    newAutoPricing: SettingData["autoPricing"]
  ) => void;
  updateAutoPricingDetailedHandler: (
    newAutoPricingDetailed: SettingData["autoPricingDetailed"]
  ) => void;
  updateAutoFinancingHandler: (
    newAutoFinancing: SettingData["autoFinancing"]
  ) => void;
  updatePreferencesHandler: (
    newPreferences: SettingData["preferences"]
  ) => void;
  updatePermissionsHandler: (
    newPermissions: SettingData["permissions"]
  ) => void;
  updateIndividualPermissionsHandler: (
    newPermissions: SettingData["individualPermissions"]
  ) => void;
  updateTeamsHandler: (newTeams: SettingData["teams"]) => void;
  updateSuppliersHandler: (newSuppliers: SettingData["suppliers"]) => void;
  updateProposalSlugHandler: (newSlug: string) => void;
  updateWebhooksHandler: (newWebhooks: SettingData["webhooks"]) => void;
  updateWppAccountsHandler: (newWppAccounts: OmnichannelData["wppAccounts"]) => void;
  updateAddonsHandler: (newAddons: AddonItem[]) => void;
};

export type SettingsContextData = {
  origins: string[];
  salesLostReasons: string[];
  rejectReasons: string[];
  notesTypes: string[];
  companyPositions: string[];
  stdFolders: string[];
  disabledSuppliers: string[];
  afterSalesServices: {
    _id: string;
    name: string;
  }[];
  addons: SettingData["addons"];
  autoPricingType: SettingData["autoPricingType"];
  autoPricingCalc: SettingData["autoPricingCalc"];
  autoPricing: SettingData["autoPricing"];
  autoPricingDetailed: SettingData["autoPricingDetailed"];
  autoFinancing: SettingData["autoFinancing"];
  preferences: SettingData["preferences"];
  permissions: SettingData["permissions"];
  individualPermissions: SettingData["individualPermissions"];
  teams: SettingData["teams"];
  suppliers: SettingData["suppliers"];
  webhooks: SettingData["webhooks"];
  wppAccounts: OmnichannelData["wppAccounts"];
  proposalSlug: string;
};

export type OmnichannelDataChatMessage = {
  id: string;
  uuid: string;
  type: WppAccountMessageType;
  content: string;
  timestamp: number;
  viewed: boolean;
  forwarded: boolean;
  ack: WppAccountMessageAck;
  fromMe: boolean;
  mediaInfo?: OmnichannelDataChatMessageMediaInfo;
};

export type OmnichannelMetaPagination = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
};

export type OmnichannelWppAccountMessagesResponse = {
  messages: OmnichannelDataChatMessage[],
  meta: OmnichannelMetaPagination
};

export type OmnichannelDataChatMessageMediaInfo = {
  key: string;
  timestamp: number;
  filehash: string;
  mimetype: string;
  size: number;
  duration?: string;
  width?: number;
  height?: number;
}

export type OmnichannelDataChat = {
  id: string,
  name: string,
  vendorName?: string,
  picture: string,
  phones: string[],
  messages: OmnichannelDataChatMessage[],
};

export type OmnichannelData = {
  _id: string;
  id: string;
  wppAccounts: {
    _id: string;
    id: string;
    user: string;
    vendor?: UserData;
    phone: string;
    session: string;
    token: string;
    qrcode: string;
    linkStatus: WppAccountLinkStatus;
    status: WppAccountStatus;
    chats: OmnichannelDataChat[],
    registryDate: string;
    registryTimestamp: number;
    lastModifiedDate: string;
    lastModifiedTimestamp: number;
  }[];
  manager: string | null;
};

export type OmnichannelsContextProps = {
  wppAccounts: OmnichannelData["wppAccounts"];
  updateWppAccountsHandler: (newWppAccounts: OmnichannelData["wppAccounts"]) => void;
};

export type OmnichannelsContextData = {
  wppAccounts: OmnichannelData["wppAccounts"];
};

export type TariffData = {
  _id: string;
  powerDistCompany: string;
  bGroupTariffs: {
    b1: {
      tusd: number;
      te: number;
      tusdTaxed: number;
      teTaxed: number;
      bWire: number;
      bWirePerc: number;
    };
  };
  aGroupTariffs: {
    a4: {
      green: {
        nonPeak: {
          tusdTaxed: number;
          teTaxed: number;
          demandTaxed: number;
        };
        peak: {
          tusdTaxed: number;
          teTaxed: number;
          demandTaxed: number;
        };
      };
      blue: {
        nonPeak: {
          tusdTaxed: number;
          teTaxed: number;
          demandTaxed: number;
        };
        peak: {
          tusdTaxed: number;
          teTaxed: number;
          demandTaxed: number;
        };
      };
    };
  };
  lastUpdated: string;
  lastUpdatedTimestamp: number;
};

export type FeeData = {
  _id: string;
  powerDistCompany: string;
  taxes: {
    imcs: number;
    pis: number;
    cofins: number;
  };
  b1Tariffs: {
    tarif: number;
    bWireNoTax: number;
  };
  a4Tariffs: {
    tusdG: number;
    green: {
      demand: number;
      nonPeak: {
        tusd: number;
        te: number;
      };
      peak: {
        tusd: number;
        te: number;
      };
    };
    blue: {
      nonPeak: {
        tusd: number;
        te: number;
        demand: number;
      };
      peak: {
        tusd: number;
        te: number;
        demand: number;
      };
    };
  };
  registryDate: string;
  registryTimestamp: number;
  lastUpdated: string;
  lastUpdatedTimestamp: number;
  user: string;
  manager: string;
};

export type SimulationResultsData = {
  roi: number;
  totalEconomy: number;
  cashFlow: number[];
  inflation: number;
  finalPrice: number;
  paybackYears: number;
  paybackMonths: number;
  noSystemMonthlyPrice: number;
  systemMonthlyPrice: number;
};

export type PortfolioItem = {
  imgUrl: string;
  location: string;
  power: number;
};

export type PartnerItem = {
  imgUrl: string;
  name: string;
};

export type WebsiteData = {
  _id: string;
  id: string;
  themeColor: string;
  companyData: {
    faviconUrl: string;
    logoUrl: string;
    logoFooterUrl: string;
    name: string;
    cnpj: string;
    address: string;
    social: {
      whatsapp: string;
      intagram: string;
      facebook: string;
    };
  };
  carouselImages: string[];
  simulator: {
    title: string;
    subtitle: string;
  };
  aboutUs: {
    imageUrl: string;
    title: string;
    p1: string;
    p2: string;
    p3: string;
  };
  goalsAndValues: {
    title: string;
    subtitle: string;
    mission: string;
    vision: string;
    values: string;
  };
  counters: {
    customers: number;
    projects: number;
    generation: number;
  };
  portfolio: {
    title: string;
    subtitle: string;
    projects: {
      _id: string;
      id: string;
      imageUrl: string;
      power: number;
      location: string;
    }[];
  };
  testimonials: {
    title: string;
    subtitle: string;
    testimonials: {
      _id: string;
      id: string;
      pictureUrl: string;
      name: string;
      testimonial: string;
    }[];
  };
  partners: {
    title: string;
    subtitle: string;
    partners: string[];
    finalCta: string;
  };
  webAnalytics: {
    pixelFb: string;
    tagGmt: string;
  };
  slug: string;
  user: string;
};

export type ContractData = {
  _id: string;
  id: string;
  name: string;
  content: string;
  fileUrl: string;
  needReupload: boolean;
  lastModified: string;
  lastModifiedTimestamp: number;
  registryDate: string;
  registryTimestamp: number;
  user: string;
};

export type ArchiveData = {
  _id: string;
  id: string;
  folders: {
    _id: string;
    id: string;
    name: string;
    order: number;
  }[];
  files: {
    _id: string;
    id: string;
    name: string;
    url: string;
    folder: string;
    order: number;
  }[];
  customer: string;
};

export type ContentData = {
  _id: string;
  id: string;
  homeCarousel: {
    _id: string;
    id: string;
    path: string;
    clickUrl: string;
  }[];
};

export type HistoryData = {
  _id: string;
  id: string;
  title: string;
  userName: string;
  userEmail: string;
  content: string;
  registryDate: string;
  registryTimestamp: number;
  user: UserData;
  customer: string;
};

export type SaleContractData = {
  _id: string;
  id: string;
  number: number;
  contractTemplateId: string;
  fileUrl: string;
  needReupload: boolean;
  name: string;
  content: string;
  lastModified: string;
  lastModifiedTimestamp: number;
  registryDate: string;
  registryTimestamp: number;
  signature: string;
};

export type SignatureData = {
  _id: string;
  id: string;
  clicksignKey: string;
  persons: {
    _id: string;
    id: string;
    name: string;
    surname: string;
    email: string;
    document: string;
    phone: string;
    auth: "email" | "whatsapp" | "sms";
    clicksignKey: string;
    clicksignSignUrl: string;
    clicksignSignatureKey: string;
    notificationSent: boolean;
  }[];
  lastModified: string;
  lastModifiedTimestamp: number;
  registryDate: string;
  registryTimestamp: number;
  user: string;
  manager: string;
  sale: string;
  saleContractId: string;
};

export type ModuleData = {
  _id: string;
  id: string;
  mModel: string;
  manufacturer: string;
  power: number;
  warrantyDefect: number;
  warrantyEfficiency: number;
  weight?: number;
  mLength?: number;
  width?: number;
  stock: number;
  price: number;
  registryDate: string;
  registryTimestamp: number;
  user: string;
  manager: string;
};

export type InverterData = {
  _id: string;
  id: string;
  iManufacturer: string;
  iModel: string;
  iPower: number | null;
  iWarranty: number | null;
  iMonitoring: string;
  iVoltage: number;
  type: "inverter" | "microinverter";
  stock: number;
  price: number;
  registryDate: string;
  registryTimestamp: number;
  user: string;
  manager: string;
};

export type EquipamentData = {
  _id: string;
  id: string;
  item: string;
  manufacturer: string;
  detail: string;
  stock: number;
  price: number;
  registryDate: string;
  registryTimestamp: number;
  user: string;
  manager: string;
};

export type ImportData = {
  _id: string;
  id: string;
  type: ImportTypes;
  status: "SUCCESS" | "WARNING" | "FAILURE" | "UNDONE";
  report: string;
  count: number;
  registryDate: string;
  registryTimestamp: number;
  undoneDate?: string;
  undoneTimestamp?: number;
  undoneByEmail?: string;
  undoneBy?: string;
  user: string;
  manager: string;
};

export type ChecklistData = {
  _id: string;
  id: string;
  items: {
    _id: string;
    id: string;
    task: string;
    answerString?: string | null;
    answerNumber?: number | null;
    answerBoolean?: boolean | null;
    templateItemId: string;
    type: "CHECKBOX" | "TEXT" | "NUMBER" | "LIST";
    list: string[];
    blockingStep: number;
    category: string;
  }[];
  categories: string[];
  itemsOrder: string[];
  type:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
  sale: string;
  kanban: string;
  manager: string;
  user: string;
};

export type SerranaSolarPanelsList = {
  message: string;
  success: number;
  items: {
    id_budget_solar_panel: number;
    name: string;
    maker: string;
    power: number;
  }[];
  pccm: {
    timestamp: number;
    version: { full: string; major: number; minor: number; patch: number };
  };
};

export type SerranaStructuresList = {
  message: string;
  success: number;
  items: {
    id_budget_structure: number;
    name: string;
    name_short: string;
    slug: string;
    can_use_structure_splice: number;
  }[];
  pccm: {
    timestamp: number;
    version: {
      full: string;
      major: number;
      minor: number;
      patch: number;
    };
  };
};

export type SerranaSystemList = {
  message: string;
  success: number;
  items: {
    id_budget_system: number;
    name: string;
    type: string;
    phases: number;
    voltages: ("127 V" | "220 V" | "220/127 V" | "380/220 V")[];
  }[];
  pccm: {
    timestamp: number;
    version: {
      full: string;
      major: number;
      minor: number;
      patch: number;
    };
  };
};

export type SerranaEqpsOpts = {
  systems: SerranaSystemList;
  pvModules: SerranaSolarPanelsList;
  fixations: SerranaStructuresList;
};

export type SerranaPayment = {
  type: string;
  name: string;
  is_on_credit: number;
  max_installments: number;
  holders: {
    santander: "Santander";
    losango: "Losango";
  };
};

export type SerranaInvertersList = {
  message: string;
  success: number;
  items: {
    id_budget_inverter: number;
    name: string;
    maker: string;
    tension: "127 V" | "220 V" | "220/127 V" | "380/220 V";
    power: number;
    mppt: number;
    warranty: string;
    stringboxes: {
      id_budget_stringbox: number;
      name: string;
      maker: string;
      warranty: string;
      is_cc: number;
      is_ca: number;
      quantity: number;
      inverterName?: string;
    }[];
  }[];
  pccm: {
    timestamp: number;
    version: {
      full: string;
      major: number;
      minor: number;
      patch: number;
    };
  };
};

export type SerranaSuggestedKit = {
  message: string;
  success: number;
  errors: any[];
  warnings: string[];
  budget: {
    calculated_power: number;
    delivery_time: string;
    desired_power: number;
    discount_type: string;
    installments: number;
    integrator: {
      id_integrator: number;
      name: string;
      email: string;
      document: string;
    };
    items: {
      autotransformers: {
        id_budget_autotransformer: number;
        name: string;
        quantity: number;
        warranty: string;
      }[];
      cable_black: { quantity: number; warranty: string };
      cable_red: { quantity: number; warranty: string };
      earthing_harnesses: { quantity: number; warranty: string };
      expansion_harnesses: { quantity: number; warranty: string };
      inverters: {
        id_budget_inverter: number;
        name: string;
        quantity: number;
      }[];
      kit_mc4: { quantity: number; warranty: string };
      kit_mc4y: { quantity: number; warranty: string };
      solar_panel: {
        id_budget_solar_panel: number;
        maker: string;
        power: number;
        quantity: number;
      };
      stringboxes: {
        id_budget_stringbox: number;
        name: string;
        quantity: number;
        is_cc: number;
        is_ca: number;
      }[];
      structure: {
        id_budget_structure: number;
        name: string;
        name_short: string;
        quantity: string;
        warranty: string;
      }[];
      system: {
        id_budget_system: number;
        name: string;
        type: string;
        phases: number;
        tension?: "127 V" | "220 V" | "220/127 V" | "380/220 V";
      };
    };
    payment_conditions: string;
    seller: {
      name: string;
      email: string;
      phone: string;
    };
    value_discount: number;
    value_discount_pct: string;
    value_integrator: number;
    value_kit: number;
    value_installment?: number;
    value_suframa: number;
    value_total: number;
  };
  pccm: {
    timestamp: number;
    version: {
      full: string;
      major: number;
      minor: number;
      patch: number;
    };
  };
};

export type CredsolarisSim = {
  dataPrimeiroVencimento: string;
  dataOperacao: string;
  taxa: number;
  prazo: number;
  valor: number;
  carencia: number;
  parcela: number;
  valorContrato: number;
  tarifa: number;
  instituicao: string;
};

export type FinancingData = {
  _id: string;
  id: string;
  externalId: string;
  status: string;
  proposals: {
    _id: string;
    id: string;
    externalId: string;
    status: string;
    contractNumber: string;
    financingTotal: number;
    installments: number;
    intallmenTotal: number;
    fee: number;
    entry: number;
    CETam: number;
    CETaa: number;
    firstDueDate: string;
    lastDueDate: string;
  }[];
  registryDate: string;
  registryTimestamp: number;
  customer: string;
  user: string;
  manager: string;
};

export enum WebhookAction {
  MOVING_BUSINESS_BETWEEN_STAGES = "MOVING_BUSINESS_BETWEEN_STAGES",
  BUSINESS_GAIN = "BUSINESS_GAIN",
  BUSINESS_LOST = "BUSINESS_LOST",
  BUSINESS_RETURN_TO_FUNNEL = "BUSINESS_RETURN_TO_FUNNEL",
  SCHEDULED_NOTE_CREATION = "SCHEDULED_NOTE_CREATION",
}

export type GenerationData = {
  _id: string;
  id: string;
  creditsMemory: number[][];
  usedCreditsMemory: number[][][];
  genMemory: number[][];
  dateMemory: { month: number; year: number }[][];
  balanceMemory: number[][][];
  simultCompensationMemory: number[][][];
  nonSimultCompensationMemory: number[][][];
  suppliedCompensationMemory: number[][][];
  suppliedCompensationPeakMemory: number[][][];
  nonSuppliedCompensationMemory: number[][][];
  nonSuppliedCompensationPeakMemory: number[][][];
  bWireMemory: number[][][];
  bWireMemoryInf: number[][][];
  minTariffMemory: number[][][];
  minTariffMemoryInf: number[][][];
  icmsMemory: number[][][];
  icmsPeakMemory: number[][][];
  icmsMemoryInf: number[][][];
  icmsPeakMemoryInf: number[][][];
  energyBillMemory: number[][][];
  energyBillPeakMemory: number[][][];
  energyBillMemoryInf: number[][][];
  energyBillPeakMemoryInf: number[][][];
  energyBillNoSystMemory: number[][][];
  energyBillNoSystPeakMemory: number[][][];
  energyBillNoSystMemoryInf: number[][][];
  energyBillNoSystPeakMemoryInf: number[][][];
  economyMemory: number[][][];
  economyPeakMemory: number[][][];
  economyMemoryInf: number[][][];
  economyPeakMemoryInf: number[][][];
  demandAmt: number[][][];
  demandGAmt: number[][][];
  demandPeakAmt: number[][][];
  demandAmtInf: number[][][];
  demandGAmtInf: number[][][];
  demandPeakAmtInf: number[][][];
  systemCashFlowNonInfMonthly: number[];
  systemCashFlowMonthly: number[];
  proposal: string;
  manager: string;
  customer: string;
};

export enum WppAccountLinkStatus {
  BROWSER_CLOSE = 'browserClose',
  DESCONNECTED_MOBILE = 'desconnectedMobile',
  NOT_LOGGED = 'notLogged',
  QR_READ_SUCCESS = 'qrReadSuccess',
  QR_READ_ERROR = 'qrReadError',
  IN_CHAT = 'inChat',
};

export enum WppAccountStatus {
  DISCONNECTED = 'disconnected',
  CONNECTED = 'connected',
};

export enum WppAccountMessageType {
  AUDIO = 'audio',
  BUTTONS_RESPONSE = 'buttons_response',
  CALL_LOG = 'call_log',
  CHAT = 'chat',
  DOCUMENT = 'document',
  GP2 = 'gp2',
  GROUP_NOTIFICATION = 'group_notification',
  IMAGE = 'image',
  LIST = 'list',
  LIST_RESPONSE = 'list_response',
  LOCATION = 'location',
  NOTIFICATION = 'notification',
  NOTIFICATION_TEMPLATE = 'notification_template',
  ORDER = 'order',
  OVERSIZED = 'oversized',
  PAYMENT = 'payment',
  POLL_CREATION = 'POLL_CREATION',
  PRODUCT = 'product',
  PROTOCOL = 'protocol',
  PTT = 'ptt',
  REVOKED = 'revoked',
  STICKER = 'sticker',
  TEMPLATE_BUTTON_REPLY = 'template_button_reply',
  UNKNOWN = 'unknown',
  VCARD = 'vcard',
  VIDEO = 'video',
};

export enum WppAccountMessageAck {
  MD_DOWNGRADE = -7,
  INACTIVE = -6,
  CONTENT_UNUPLOADABLE = -5,
  CONTENT_TOO_BIG = -4,
  CONTENT_GONE = -3,
  EXPIRED = -2,
  FAILED = -1,
  CLOCK = 0,
  SENT = 1,
  RECEIVED = 2,
  READ = 3,
  PLAYED = 4,
};

export enum AddonType {
  OMNICHANNEL = 'omnichannel',
};

export enum AddonOmnichannelType {
  WHATSAPP = 'whatsapp',
};

export enum AddonOmnichannelStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
};

export type AddonOmnichannel = {
  uuid: string;
  type: AddonOmnichannelType;
  total: number;
  used: number;
  status: AddonOmnichannelStatus;
  validityTimestamp: number;
};

export type AddonItem = {
  type: AddonType;
  item: AddonOmnichannel;
};

export type NewAddonResponse = {
  addons: AddonItem[];
};

export type GetAddonStatusResponse = {
  stauts: "PENDING" | "APPROVED" | "REJECTED";
  addon: AddonItem;
};
